import React, { useCallback, useContext, useEffect, useState } from "react";
import styleModal from "./Modal.module.scss";
import CloseButtonModal from "../../assets/images/close_button_modal.svg";
import CloseButtonModalMobile from "../../assets/images/IconModal/Arrow_left_carousel.svg";
import { eventType, pushEvent } from "../../utils/EventListener";
import { isDesktopView } from "../../utils/Sizing";
import { Search } from "../../context/LanguageContext";
import { HumburgerMenuContext } from "../../context/HumburgerMenuContext";
import { ModalContext } from "../../context/ModalContext";
import { RENDERING_SYSTEM, useCarousel } from "../../context/CarouselContext";
import { NavbarStickyContext } from "../../context/NavbarStickyContext";

function Modal(props) {
  const { setMenuIsShown } = useContext(HumburgerMenuContext);
  const { modalContent } = useContext(ModalContext);
  const { navbarStickyContent } = useContext(NavbarStickyContext);
  const { state: carouselState, ...setters } = useCarousel();

  function checkMobileSmartSelctionModal() {
    return (
      !isDesktopView() &&
      props.isSmartSelectionModal !== undefined &&
      props.isSmartSelectionModal
    );
  }

  const renderBody = useCallback(() => {
    let result = "";
    if (props?.bodyContent) {
      result = props.bodyContent;
    }
    return result;
  }, [props?.bodyContent]);

  function CreateButtonClose() {
    return (
      <>
        {props.headerContent}
        <button
          id="btn-close-modal"
          type="button"
          className={styleModal["btn-close-modal"]}
          data-bs-dismiss={props.return ? "" : "modal"}
          data-bs-toggle={props.return ? "modal" : ""}
          data-bs-target={props.return ? props.returnText : ""}
          onClick={() => {
            if (props.returnCard) {
              pushEvent(eventType.cardItemAccordionEvent, props.returnCard);
            }
            if (props.closeButton) {
              if (
                props.idCheckedCloseButton ||
                modalContent?.idCheckedCloseButton
              ) {
                document.getElementById(
                  props.idCheckedCloseButton
                ).checked = false;
              }

              const modal = document.getElementById(props.id);
              modal.style.display = "none";
              modal.classList.remove("show");
            }

            if (!isDesktopView()) {
              if (props.ontopOfNavConfig) {
                setMenuIsShown({
                  hideNav: false,
                  hideHeader: false,
                  hideRecapBar: false,
                });
                if (
                  carouselState?.rendering === RENDERING_SYSTEM.mhp &&
                  navbarStickyContent?.isSticky
                ) {
                  setters.setShowArrow(true);
                }
              }
            }
          }}
          aria-label="Close"
          tabIndex="0"
        >
          {props.closeText ? (
            <span className={styleModal["font-close-text"]}>
              <Search did="modal_labels" tid="modal.button.close" />
            </span>
          ) : (
            ""
          )}
          {props.closeTextMobile ? (
            <span
              className={"d-lg-none ".concat(styleModal["font-close-text"])}
            >
              <Search did="modal_labels" tid="modal.button.close" />
            </span>
          ) : (
            ""
          )}
          {checkMobileSmartSelctionModal() ? (
            <div className="m-0 p-0 border-0">
              <img src={CloseButtonModalMobile} alt="CloseButtonModalMobile" />
              <span
                className={`d-lg-none ps-3 ${styleModal["font-close-text"]}`}
              >
                <Search did="header_menu" tid="back" />
              </span>
            </div>
          ) : (
            <img src={CloseButtonModal} alt="CloseButtonModal" />
          )}
        </button>
      </>
    );
  }

  function createModal() {
    return (
      <div
        className={`modal fade px-0 ${styleModal.background} ${
          props.onTop ? styleModal.mandatory : ""
        }`}
        id={props.id}
        aria-labelledby={
          props.ariaLabelledby ? props.ariaLabelledby : "ariaLabelledby"
        }
        aria-hidden="true"
        data-bs-backdrop="false"
        tabIndex="-1"
        data-keyboard="true"
      >
        <div className={"modal-dialog ".concat(props.style1)}>
          <div
            className={"modal-content ".concat(
              props.style2 || modalContent?.style2 || ""
            )}
          >
            {props.detailOptionalModalPage ? (
              ""
            ) : (
              <div
                className={
                  props.headerHidden
                    ? "d-none"
                    : `modal-header border-bottom-0 ${
                        checkMobileSmartSelctionModal()
                          ? "justify-content-start"
                          : "justify-content-end"
                      }`
                }
              >
                {CreateButtonClose()}
              </div>
            )}
            <div
              className={`modal-body p-0 ${
                props.isApparel ? "mx-0 p-0" : "ps-lg-0"
              }  ${styleModal["max-height"]} ${props.style3}`}
            >
              {renderBody()}
              {props.closeButtonEnd && (
                <div
                  className={`modal-header border-bottom-0 ${
                    checkMobileSmartSelctionModal()
                      ? "justify-content-start"
                      : "justify-content-end"
                  }`}
                >
                  {CreateButtonClose()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return createModal();
}

export default Modal;
