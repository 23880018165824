import React, { useContext, useEffect, useState } from "react";
import style from "./ExpandedMenu.module.scss";
import More from "../../../assets/images/navbar/More.svg";
import CloseButtonModal from "../../../assets/images/close_button_modal.svg";
import { Search } from "../../../context/LanguageContext";
import { HumburgerMenuContext } from "../../../context/HumburgerMenuContext";
import { isDesktopView } from "../../../utils/Sizing";

function ExpandedMenu({
  itemList = [],
  currentPosition,
  updatePosition,
  isNavbarConfig = false,
}) {
  const { setMenuIsShown } = useContext(HumburgerMenuContext);
  const [isShown, setIsShown] = useState(false);
  const [display, setDisplay] = useState(" d-none ");

  useEffect(() => {
    if (isShown) {
      setDisplay(" d-flex ");
    } else {
      setDisplay(" d-none ");
    }
  }, [isShown]);

  function handleButton(item, scollToRefIndex) {
    if (isNavbarConfig) {
      updatePosition(item, scollToRefIndex);
      if (!isDesktopView()) {
        setMenuIsShown({
          hideNav: false,
          hideHeader: false,
          hideRecapBar: false,
        });
      }
    } else {
      updatePosition(item);
    }
    closeMenu();
  }

  function closeMenu() {
    document.body.style.overflow = "auto";
    setIsShown(false);
    if (!isDesktopView()) {
      setMenuIsShown({
        hideNav: false,
        hideHeader: false,
        hideRecapBar: false,
      });
    }
  }

  return (
    <div
      data-component="expanded-menu-container"
      className={`${
        isNavbarConfig ? "col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1" : "w-100"
      } p-0 text-center ${style["expanded-menu-container"]}`}
    >
      <button
        className={`btn w-100 p-0 ${style["more-button"]}`}
        type="button"
        onClick={() => {
          document.body.style.overflow = "hidden";
          setIsShown(true);
          setMenuIsShown({
            hideNav: false,
            hideHeader: false,
            hideRecapBar: true,
          });
        }}
      >
        <img src={More} alt="More" />
      </button>
      <div
        data-component="expanded-menu-content"
        className={`container-fluid p-0 ${
          display === " d-flex "
            ? ` ${style.expanded_menu_content} ${style.slide_from_right}`
            : ` ${style.expanded_menu_content} ${style.slide_from_left}`
        }`}
      >
        <div
          data-component="expanded-menu-header"
          className={`row mx-0 mt-3 mb-5 px-0 ${style["expanded-menu-header"]}`}
        >
          <p className={`ms-3 mb-0 ${style.title}`}>
            <Search did="navbar_configurator" tid="expanded.menu.title" />
          </p>
          <button
            type="button"
            className={`btn w-auto p-1 ${style["btn-close-modal"]}`}
            onClick={() => {
              closeMenu();
            }}
          >
            <img src={CloseButtonModal} alt="CloseButtonModal" />
          </button>
        </div>
        <div data-component="expanded-menu-list" className="row ms-5 me-0 px-0">
          <ul className="m-0 p-0">
            {currentPosition?.position &&
              itemList.length > 0 &&
              itemList.map((item, i) => {
                const buttonItemId = "expanded_item".concat(i + 1);
                const active =
                  item.position === currentPosition.position
                    ? style["btnlabel-active"]
                    : "";
                return (
                  <li key={"item-expanded".concat(i + 1)} className="p-0">
                    <button
                      id={buttonItemId}
                      type="button"
                      className={`btn ${style["expanded-menu-button"]}`}
                      onClick={() => {
                        handleButton(item, i);
                      }}
                    >
                      <p
                        className={`mb-1 ps-4 pt-2 pb-2 pe-4 ${style.btnlabel} ${active}`}
                      >{`${(i + 1).toString()}. ${item.step}`}</p>
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ExpandedMenu;
